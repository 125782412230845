import '@cinch-labs/design-system/dist/index.css'
import { registerRumContext } from '@cinch-labs/shared-util'
import { useUserStore } from '@cinch-nx/data-user'
import { ErrorBoundaryWrapper } from '@cinch-nx/landing-feature-error-page'
import {
  Layout,
  AppPropsWithLayoutPropsWithError,
} from '@cinch-nx/landing-ui-shared'
import {
  AnalyticsManager,
  preserveReferrerAndCID,
} from '@cinch-nx/shared-analytics'
import { CookieConsentWrapper } from '@cinch-nx/shared-ui'
import Script from 'next/script'
import { useEffect, useState } from 'react'
import 'react-slidedown/lib/slidedown.css'

import '../stores'
import './_app.css'

function CustomApp({
  Component,
  pageProps,
  err,
}: AppPropsWithLayoutPropsWithError) {
  const { layoutProps, ...componentProps } = pageProps

  const BUILD_ID = process.env.NEXT_PUBLIC_BUILD_ID
  registerRumContext(BUILD_ID)
  const [story, setChangedStory] = useState(pageProps.story)
  const { fetchUser } = useUserStore()

  useEffect(() => {
    preserveReferrerAndCID()
    fetchUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnload = () => {
    const { StoryblokBridge, location } = window
    const storyblokInstance = new StoryblokBridge()
    storyblokInstance.on(['published', 'change'], () => {
      // reload page if save or publish is clicked
      location.reload()
    })

    storyblokInstance.on('input', (payload) => {
      setChangedStory(payload.story)
    })
  }
  if (componentProps.story && story) {
    componentProps.story = story
  }
  return (
    <>
      <ErrorBoundaryWrapper>
        <AnalyticsManager />
        {pageProps.preview && (
          // eslint-disable-next-line @next/next/no-before-interactive-script-outside-document
          <Script
            src="https://app.storyblok.com/f/storyblok-v2-latest.js"
            type="text/javascript"
            onLoad={handleOnload}
          />
        )}
        <Layout {...layoutProps} useVerticalGradient>
          <>
            <Component {...componentProps} err={err} />
            <CookieConsentWrapper />
          </>
        </Layout>
      </ErrorBoundaryWrapper>
    </>
  )
}

export default CustomApp
